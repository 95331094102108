.chat {
  background-color: #f2f2f2;
  height: 200px;
  overflow-y: scroll;
  border-radius: 4px;
}

.messageList {
  padding: 10px;
}

.chat-input {
  position: absolute;
  bottom: 0px;
  width: 200px;
  height: 25px;
}

.send-chat-button {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 50px;
  height: 31px;
  background-color: #f2f2f2;
  font-weight: bold;
}
