@import url(https://fonts.googleapis.com/css?family=Titan+One:300,400,500,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Quicksand', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.call {
  margin-top: 10px;
  flex-wrap: wrap;
  display: inline-flex;
  justify-content: center;
  height: 100%;
}

.large-tiles {
}

.small-tiles {

}

.tile.small {
  position: relative;
}

.tile.large {
  position: relative;
}

.tile video {
  width: 95vw;
  height: 20vh;
  top: 0px;
  margin-bottom: 10px;
  background-color: #000000;
  box-shadow: 4px 4px 0px #BD4291;
}

.tile .background {
  background-color: #000000;
  width: 100%;
  padding-top: 56.25%; /* Hard-coded 16:9 aspect ratio */
}

.tile.local video {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}

.tile.small video {
  border-radius: 4px;
}

.tile.small .background {
  border-radius: 4px;
}

/* .tile .overlay {
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 17px;
}

.tile .corner {
  position: absolute;
  color: #ffffff;
  background-color: #000000;
  padding: 10px;
  margin: 0;
  bottom: 0;
  left: 0;
  font-size: 14px;
  line-height: 17px;
} */

.call-message {
  width: auto;
  padding: 20px 30px;
  top: 50%;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
}

.call-message.error {
  color: #d81a1a;
  background-color: #ffffff;
}

.call-message-header {
  font-weight: bold;
}

.start-button {
  padding: 20px 30px;
  position: absolute;
  background: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #4a4a4a;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #ffffff;
}

.mainScreen {

}

.mainScreen .title {
  color: white;
  text-align: center;
  font-weight: 300;
}

.mainScreen button {
  font-family: 'Titan One', sans-serif;
  background: #364F6B;
  box-shadow: 4px 4px 0px #F5F5F5;
  color: white;
  border: none;
}
.start-button {
  padding: 20px 30px;
  position: absolute;
  background: #ffffff;
  font-family: 'Titan One', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #4a4a4a;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #ffffff;
}

.mainScreen {

}

.mainScreen .title {
  color: white;
  text-align: center;
  color: white;
  text-align: center;
  font-weight: 300;
}
.start-button {
  padding: 20px 30px;
  position: absolute;
  background: #ffffff;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #4a4a4a;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #ffffff;
}

.mainScreen {

}

.mainScreen .title {
  color: white;
  text-align: center;
}

.opinions .optionsW {
  display: flex;
  justify-content: center;
}

.opinions .options {
  width: 95vw;
}

.opinions h1 {
  background-color: #BD4291;
  padding: 12px 0 12px 0;
  border: 3px solid pink;
  border-width: 3px 0 3px 0;
  font-weight: 300;
}

.opinions .option {
  display: flex;
  vertical-align: center;
  align-items: center;
  justify-content: center;
  background-color: #364F6B;
  width: 100%;
  margin-bottom: 10px;
  font-family: 'Titan One', sans-serif;
  color: white;
}

.opinions .a {
  box-shadow: 4px 4px 0px #4E9F3D;
}

.optionsW h2 {
  font-family: 'Titan One', sans-serif;
  color: white;
  font-weight: 300;
  text-align: center;
}

.opinions .b {
  box-shadow: 4px 4px 0px #F94C66;
}

.opinions .option img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.app {
  background-color: #61C0BF;
  position: absolute;
  height: 100%;
  width: 100%;
  font-family: 'Titan One', sans-serif;
  letter-spacing: 3px;
  font-weight: 400;
}

.username-pick {
  background-color: #61C0BF;
  position: absolute;
  height: 100%;
  width: 100%;
}

.username-pick form {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.username-pick h1 {
  color: white;
  text-align: center;
  font-family: 'Titan One', sans-serif;
  font-weight: 300;
}

.username-pick input {
  background: none;
  border: 2px solid white;
  border-width: 0 0 2px 0;
  padding: 10px;
  font-size: 22px;
  color: white;
  font-family: 'Titan One', sans-serif;
}

.username-pick input:focus {
  outline: none;
}

.username-pick button {
  font-size: 20px;
  width: 50%;
  padding: 10px;
  margin-top: 40px;
  font-family: 'Titan One', sans-serif;
  background: #364F6B;
  box-shadow: 4px 4px 0px #F5F5F5;
  color: white;
  border: none;
}

.opinionOptions .overview h1 {
  font-size: 15px;
  font-weight: 300;
}

.opinionOptions .overview h2 {
  font-size: 11px;
  font-weight: 300;
}

.opinionOptions .overview {
  background-color: #BD4291;
  padding: 12px 0 12px 0;
  color: white;
  border: 3px solid pink;
  border-width: 3px 0 3px 0;
  font-size: 15px;
  text-align: center;
  font-weight: 300;
}

.buttonChoicesW {
  display: flex;
  justify-content: center;
}

.buttonChoices .buttonChoice1 button {
  font-size: 20px;
  padding: 10px;
  margin-top: 40px;
  font-family: 'Titan One', sans-serif;
  background: #4E9F3D;
  box-shadow: 4px 4px 0px #F5F5F5;
  color: white;
  border: none;
}

.buttonChoices .buttonChoice2 button {
  font-size: 20px;
  padding: 10px;
  margin-top: 40px;
  font-family: 'Titan One', sans-serif;
  background: #F94C66;
  box-shadow: 4px 4px 0px #F5F5F5;
  color: white;
  border: none;
}
.chatbox {

}

.tray {
  flex: none;
  height: 60px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.tray-button {
  width: 44px;
  height: 44px;
  border: none;
  background-color: transparent;
  margin: 0 5px;
}

.tray-button:disabled {
  opacity: 33%;
}

.tray-button:enabled {
  cursor: pointer;
}

/* Acts as the first button in a new "group" of buttons separated from those to the left of it */
.tray-button.new-group {
  margin-left: auto;
}

.chat {
  background-color: #f2f2f2;
  height: 200px;
  overflow-y: scroll;
  border-radius: 4px;
}

.messageList {
  padding: 10px;
}

.chat-input {
  position: absolute;
  bottom: 0px;
  width: 200px;
  height: 25px;
}

.send-chat-button {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 50px;
  height: 31px;
  background-color: #f2f2f2;
  font-weight: bold;
}

.browser-unsupported {
  position: absolute;
  background: #ffffff;
  font-family: Helvetica Neue;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #4a4a4a;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

