.start-button {
  padding: 20px 30px;
  position: absolute;
  background: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #4a4a4a;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}

.mainScreen {

}

.mainScreen .title {
  color: white;
  text-align: center;
  font-weight: 300;
}

.mainScreen button {
  font-family: 'Titan One', sans-serif;
  background: #364F6B;
  box-shadow: 4px 4px 0px #F5F5F5;
  color: white;
  border: none;
}