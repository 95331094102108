.start-button {
  padding: 20px 30px;
  position: absolute;
  background: #ffffff;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #4a4a4a;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}

.mainScreen {

}

.mainScreen .title {
  color: white;
  text-align: center;
}

.opinions .optionsW {
  display: flex;
  justify-content: center;
}

.opinions .options {
  width: 95vw;
}

.opinions h1 {
  background-color: #BD4291;
  padding: 12px 0 12px 0;
  border: 3px solid pink;
  border-width: 3px 0 3px 0;
  font-weight: 300;
}

.opinions .option {
  display: flex;
  vertical-align: center;
  align-items: center;
  justify-content: center;
  background-color: #364F6B;
  width: 100%;
  margin-bottom: 10px;
  font-family: 'Titan One', sans-serif;
  color: white;
}

.opinions .a {
  box-shadow: 4px 4px 0px #4E9F3D;
}

.optionsW h2 {
  font-family: 'Titan One', sans-serif;
  color: white;
  font-weight: 300;
  text-align: center;
}

.opinions .b {
  box-shadow: 4px 4px 0px #F94C66;
}

.opinions .option img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}