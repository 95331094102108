.call {
  margin-top: 10px;
  flex-wrap: wrap;
  display: inline-flex;
  justify-content: center;
  height: 100%;
}

.large-tiles {
}

.small-tiles {

}
