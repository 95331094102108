.chatbox {

}

.tray {
  flex: none;
  height: 60px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
