@import url('https://fonts.googleapis.com/css?family=Titan+One:300,400,500,700');

.app {
  background-color: #61C0BF;
  position: absolute;
  height: 100%;
  width: 100%;
  font-family: 'Titan One', sans-serif;
  letter-spacing: 3px;
  font-weight: 400;
}

.username-pick {
  background-color: #61C0BF;
  position: absolute;
  height: 100%;
  width: 100%;
}

.username-pick form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.username-pick h1 {
  color: white;
  text-align: center;
  font-family: 'Titan One', sans-serif;
  font-weight: 300;
}

.username-pick input {
  background: none;
  border: 2px solid white;
  border-width: 0 0 2px 0;
  padding: 10px;
  font-size: 22px;
  color: white;
  font-family: 'Titan One', sans-serif;
}

.username-pick input:focus {
  outline: none;
}

.username-pick button {
  font-size: 20px;
  width: 50%;
  padding: 10px;
  margin-top: 40px;
  font-family: 'Titan One', sans-serif;
  background: #364F6B;
  box-shadow: 4px 4px 0px #F5F5F5;
  color: white;
  border: none;
}

.opinionOptions .overview h1 {
  font-size: 15px;
  font-weight: 300;
}

.opinionOptions .overview h2 {
  font-size: 11px;
  font-weight: 300;
}

.opinionOptions .overview {
  background-color: #BD4291;
  padding: 12px 0 12px 0;
  color: white;
  border: 3px solid pink;
  border-width: 3px 0 3px 0;
  font-size: 15px;
  text-align: center;
  font-weight: 300;
}

.buttonChoicesW {
  display: flex;
  justify-content: center;
}

.buttonChoices .buttonChoice1 button {
  font-size: 20px;
  padding: 10px;
  margin-top: 40px;
  font-family: 'Titan One', sans-serif;
  background: #4E9F3D;
  box-shadow: 4px 4px 0px #F5F5F5;
  color: white;
  border: none;
}

.buttonChoices .buttonChoice2 button {
  font-size: 20px;
  padding: 10px;
  margin-top: 40px;
  font-family: 'Titan One', sans-serif;
  background: #F94C66;
  box-shadow: 4px 4px 0px #F5F5F5;
  color: white;
  border: none;
}