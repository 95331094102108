.tray-button {
  width: 44px;
  height: 44px;
  border: none;
  background-color: transparent;
  margin: 0 5px;
}

.tray-button:disabled {
  opacity: 33%;
}

.tray-button:enabled {
  cursor: pointer;
}

/* Acts as the first button in a new "group" of buttons separated from those to the left of it */
.tray-button.new-group {
  margin-left: auto;
}
